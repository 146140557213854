.btn {
  display: inline-block;
  padding:12px 16px;
  background: transparent;
  text-decoration: none;
  line-height:1;
  border-radius: 2px;
  border: 1px solid transparent;
}

.btn-orange {
  color: #ffffff;
  background-color: #FF9C0E;
  border-color: #FF9C0E;

  &:hover {
    color: #ffffff;
    background: #FEA72B;
  }
}

.btn-light {
  border-color: #3281C8;
}

.btn-block {
  display: block;
  text-align: center;
}

.btn-link {
  padding:0;
  border:0;
  color: #3281C8;
}

.btn-low {
  padding:6px 16px;
}

.dropdown {
  position: relative;
  display: inline-block;

  .btn {
    &:hover {
      background: #005D9E;
      color: #FFFFFF;
    }
  }
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #ffffff;
  min-width: 262px;
  z-index: 1;

  left: 50%;
  transform: translateX(-50%);
  @extend .-shadow-;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;

  &:hover {
    background-color: #0075C7;
    color: #FFFFFF;
  }
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}

.dropdown-info {

  .dropdown-content {
    font-size:14px;
    padding:20px;
  }

  .btn {
    font-size:14px;

    &:hover {
      background: transparent;
      color: #48A9ED;
    }
  }
}
