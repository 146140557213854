.infoBlock-icon {
  float: left;
  margin:4px 18px 0 0;
}

.-bordered-top- {
  border-top: 1px solid #DAE9F6;
  padding-top:28px;
}

.examProtocol-list {}
.examProtocol-list--item {
  margin: 0 0 57px;

  .-bordered- {
    padding:28px 0;

    .infoBlock {
      margin:0;
    }
  }
}

.examProtocol-card {
  @extend .-shadow-;
  background: #FFFFFF;
  padding:48px 60px;
}

.examProtocol-card--dateTimePlace {
  margin: 0 0 20px;

  .dateTimePlace {
    padding:0 0 28px 0;
  }
}

.examProtocol-card--mainInfo {
  border-bottom: 1px solid #DAE9F6;
  margin: 0 0 20px;
}


.examProtocol-card--user-list {
  margin:15px 0 0;
}

.examProtocol-card--user-item {
  margin: 0 0 25px;
  padding:22px 20px;
  border-radius: 3px;
  border: 1px solid #DAE9F6;


  .user-item--icon {
    margin:0 10px 0 0;
  }

  .user-item--name {
    vertical-align: middle;
  }

  .user-item--result {
    float: right;
  }
}
