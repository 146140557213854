ul {
  padding-left:18px;
  li {
    margin: 0 0 20px;
  }
}


.icon {
  display: inline-block;
  background: #000;
  vertical-align: middle;

  &.reg {
    height:58px;
    width:55px;
    background-size:contain;
    background: url('/assets/img/reg.svg');
  }

  &.sign-up {
    height:58px;
    width:51px;
    background-size:contain;
    background: url('/assets/img/zap.svg');
  }

  &.pay {
    height:58px;
    width:58px;
    background-size:contain;
    background: url('/assets/img/pay.svg');
  }

  &.come {
    height:58px;
    width:58px;
    background-size:contain;
    background: url('/assets/img/comeon.svg');
  }

  &.get-verified {
    height:58px;
    width:56px;
    background-size:contain;
    background: url('/assets/img/results.svg');
  }

  &.getinfo {
    height:58px;
    width:52px;
    background-size:contain;
    background: url('/assets/img/getinfo.svg');
  }

  &.demo {
    height:52px;
    width:58px;
    background-size:contain;
    background: url('/assets/img/demo.svg');
  }

  &.exam {
    height:57px;
    width:48px;
    background-size:contain;
    background: url('/assets/img/exam.svg') no-repeat;
  }

  &.no-time {
    height:48px;
    width:47px;
    background-size:contain;
    background: url('/assets/img/no-time.svg');
  }

  &.pdf {
    height:16px;
    width:16px;
    background-size:contain;
    background: url('/assets/img/download.svg');
  }

  &.question {
    height:45px;
    width:48px;
    background-size:contain;
    background: url('/assets/img/faq.svg');
  }

  &.user {
    height:42px;
    width:42px;
    background-size:contain;
    background: url('/assets/img/user.svg');
  }

  &.user-mini {
    height:15px;
    width:13px;
    background-size:contain;
    background: url('/assets/img/user-mini.svg');
  }

}

.iconed-list {

}

.iconed-list--item {
  border-top: 1px solid #D5E4EF;
  padding:30px;

  &:last-child {
    border-bottom: 1px solid #D5E4EF;
  }

  .ili--img {
    float: left;
    margin:0 34px 0 0;
  }

  .ili--content {
    margin:0 0 0 82px;
  }

  .ili--title {
    font-weight:bold;
    margin:0 0 16px;
    font-size:18px;
    line-height:1;
  }
}

.docs-list {
  //display: flex;
  align-items: center;
  justify-content: space-between;
  margin:0 0 40px;
}

.docs-item {
  background: #ffffff;
  display: inline-block;
  width:270px;
  padding:28px 32px;
  border: 1px solid transparent;
  font-size:14px;
  margin:0 20px 20px 0;
  color: #000000;

  &:hover {
    color: #000000;

    .docs-item--type {

      .docs-item--type-format {
        transform: translateY(-100%) translate3d(0,0,0);
        opacity: 0;
      }

      .docs-item--type-download {
        transform: translateY(-100%) translate3d(0,0,0);
        opacity: 1;
      }
    }
  }

  @extend .block-shadow;

  .docs-item--info {
    border-top: 1px solid #DAE9F6;
    margin:23px 0 0 0;

    font-size:16px;
  }

  .docs-item--name {
    height:108px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .docs-item--type {
    float: left;
    transition: all 0.2s;
    height:43px;
    padding:23px 0 0;
    overflow: hidden;

    i {
      margin:2px 5px 0 0;
      float: left;
    }


    .docs-item--type-format {
      margin:0 0 0 24px;
      opacity: 1;
      display: block;
      transition: opacity .3s cubic-bezier(.455,.03,.515,.955),transform .3s cubic-bezier(.455,.03,.515,.955);
      vertical-align: middle;
    }

    .docs-item--type-download {
      margin:0 0 0 24px;
      color: #1F80C5;
      opacity: 0;
      display: block;
      transition: opacity .3s cubic-bezier(.455,.03,.515,.955),transform .3s cubic-bezier(.455,.03,.515,.955);
    }
  }

   .docs-item--size {
     float: right;
     padding:23px 0 0;
   }
}


