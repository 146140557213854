::selection{
  background: #333;
  color: #fff;
  text-shadow: none;
}
::-moz-selection{
  background: #333;
  color: #fff;
  text-shadow: none;
}
::-webkit-selection{
  background: #333;
  color: #fff;
  text-shadow: none;
}

.-hidden- {
  display: none;
}

.fancybox-overlay {
  background: rgba(41,66,86,0.95) !important;
}
