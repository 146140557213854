.sidebar {
  float: left;
  width:271px;
  padding:57px 0 0;
}

.sidebar-nav {
  background-color: #EBF6FC;
  border-radius: 3px;
  overflow: hidden;

  a {
    display: block;
    padding:10px 16px;
    color: #677079;

    &.active {
      background: #1C7FC4;
      color: #ffffff;
    }
  }
}

.sidebar-action-btn {

  .btn {
    display: block;
    text-align: center;
  }

  margin:0 0 36px;
}

.sidebar-filter {

}

.sidebar-filter--input-item {
  margin: 0 0 18px;

  .input-item--label {
    color: #899EA8;
    margin:0 0 11px;
    font-size:14px;
    display: block;
  }

  input[type='text'],select {
    display: block;
    background: #F4F7FB;
    border: 1px solid #B6C7E1;
    padding:10px 16px;
    font-size:14px;
    width:100%;
    color: #4D5660;
    border-radius: 2px;
  }

  .select {
    option {
      color: #4D5660
    }
  }

  .input-item--submit {
    display: block;
    margin:32px 0 16px;
    width:100%;
    background: #C0CCDE;
    color: #5C6A80;
    text-align: center;
    padding:8px;
    border:none;
    border-radius: 2px;

    &:hover {
      cursor: pointer;
    }

  }

  .input-item--reset {
    text-align: center;
    color: #4F94CC;
    font-size:14px;
    background: transparent;
    display: block;
    width:100%;
    border: none;

    &:hover {
      cursor: pointer;
    }
  }
}

.sidebar-demoExam {
  i {
    display: block;
    margin:0 auto 30px;
  }
  p {
    margin: 0 0 30px;
  }
}

.sidebar-request-actions {
  margin:0 -46px 40px;
}

.sidebar-request-actions--title {
  margin: 0 0 10px;
  padding:0 46px;
  color: #899EA8;
}

.sidebar-request-actions--item {
  display: block;
  padding:10px 46px;
  color: #000000;

  &:hover {
    background: #0075C7;
    color: #FFFFFF;
  }

  &.active {
    background: #0075C7;
    color: #FFFFFF;
  }
}

