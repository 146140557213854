.footer {
  margin: -111px 0 0 0;
  padding:26px 0;
  height: 111px;
  background-color: #D8E6F8;
  background: linear-gradient(90deg, #F0EDFC 0%, #E5EAFA 61.6%, #C4DFF6 100%);
  color: #4F6F99;

  body.inverse & {
    background: #ffffff;
  }

}

.footer-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
}

.footer--contacts {
  //float: left;

  nav {
    float: left;
    //width:250px;
    font-size:14px;
    margin:0 40px 0 0;

    a {
      display: block;
    }
  }

}

.footer-copyright {
  //float: right;
}
