//exam
.exam-header {
  background: #ffffff;
  padding:0 20px ;
  @extend .-shadow-;
  border: none;
  height:70px;

  .header--logo {
    margin:4px 20px 0 0;
  }

  .header-nav {
    font-size:0;

    a {
      color: #000000;
      vertical-align: middle;
      min-height: 100%;
      line-height:70px;
      padding:0 24px;
      font-size:16px;
      display: inline-block;

      &:hover {
        background: #1C7FC4;
        color: #ffffff;
      }

      &.active {
        background: #1C7FC4;
        color: #ffffff;
      }
    }
  }
}

.exam-panel {
  background: #ffffff;
  padding:54px 63px;

  @extend .-shadow-;
  border: none;
}

.exam-desc {
  margin:0 0 30px;
  font-size:18px;
}

.input-item--desc {
  margin:12px 0 0;
  color: #B4BFCB;
}

.exam-sidebar {
  background: #DAE3F1;
  min-height: 100%;
  height:100%;
  padding:40px 46px;
  width:300px;
  position: relative;


}


.exam-timer {

  .exam-timer--title {
    text-align: center;
    font-weight:normal;
    color: #000000;
  }
}

.exam-info {
  span {
    color: #1580C5;
    font-size:16px;
    font-weight:bold;
  }
}

.exam-issues-counter--square-list {
  margin:20px 0;
  font-size:0;

  .square-list--item {
    display: inline-block;
    height:16px;
    width:16px;
    border-radius: 3px;
    margin: 0 11px 11px 0;
    overflow: hidden;
    background: #EBF0F8;

    &:hover {
      cursor: pointer;
    }

    &.active {
      background: #FF9C0E;
    }

    &.done {
      background: #1C7FC4;
    }
  }
}

.instruction-container {
  height:500px;
  position: relative;
  margin: 0 0 35px;
}

.instruction-container--ow {
  max-height:100%;
  overflow:auto;
  border-bottom:1px solid #DAE9F6;
}

.instruction-content {}

.exam-sidebar--exam-info {
  margin:0 0 24px;

  h3 {
    color: #899EA8;
    font-weight:normal;
    margin: 0 0 12px;
    font-size:16px;
  }

}

.test-item--title {
  font-weight:normal;
  margin:0 0 36px;
  color: #899EA8;
  font-size:20px;
}

.test-item--question-subj {
  font-size:18px;
  margin:0 0 24px;
}

.test-item--answer-desc {
  color: #899EA8;
  margin:0 0 26px;
}

.test-item--answer-body {
  margin: 0 0 40px;

  &.-radio- {
    .radio-label {
      display: block;
      margin:0 0 20px;
    }

    input[type='radio'] {
      margin:0 10px 0 0;
    }
  }

  &.-checkbox- {
    .checkbox--label {
      display: block;
      margin:0 0 20px;
    }

  }

  &.-sortable- {
    .sortable-list {

    }

    .sortable-list--item {
      display: block;
      width:100%;
      background-color: #EBF6FC;
      border:none;
      padding:12px 14px 12px 28px;
      font-size:16px;
      margin:0 0 20px;
      position: relative;

      &:after {
        content: '';
        display: block;
        height:15px;
        width:0;
        border-left:3px dotted #A6BDC9;
        position: absolute;
        top:0;
        bottom: 0;
        margin:auto;
        left:14px;
      }

      &:hover {
        cursor: move;
      }
    }

    &.-two-sortable-list- {
      .sortable-list--item {
        height:64px;
        vertical-align: middle;
      }

      .sortable-column {
        width:48%;
        display: inline-block;

        &:last-child {
          float: right;
        }
      }
    }
  }
}

.test-item--footer {
  border-top:1px solid #DAE9F6;
  padding:36px 0 0;

  .skip-btn {
    float: right;
  }
}

.lines {
  text-align: center;

  span {
    height:32px;
    width:18px;
    vertical-align: 64px;
    display: block;
    margin:0 auto 52px;
    border-bottom: 1px solid #0075C7;
  }
}

.radio-label {
  &:hover {
    cursor: pointer;
  }

  .iradio_flat-blue {
    float: left;
  }

  .icheckbox_flat-blue {
    float: left;
  }

  .label-text {
    display: block;
    margin: 0 0 0 33px;
  }
}

.checkbox--label {
  &:hover {
    cursor: pointer;
  }

  .iradio_flat-blue {
    float: left;
  }

  .icheckbox_flat-blue {
    float: left;
  }

  .label-text {
    display: block;
    margin: 0 0 0 33px;
  }
}
