.jobConstructor-qualificationFilter-results {
  margin:0 0 32px;

  .results-found {
    float: left;
  }

  .results-total{
    float: right;
    color: #899EA8;
  }
}

.jobConstructor-qualQuestionsList {
  @extend .-shadow-;

  background: #ffffff;
  padding:50px 60px;
  margin:0 0 62px;

  .qualQuestionsList-about {
    color: #899EA8;
    margin-bottom:10px;
  }

  .qualQuestionsList-title {
    color: #1C7FC4;
    font-weight: normal;
    font-size:18px;
    margin:0 0 32px;
    line-height:1;
  }
}

.qualQuestionsList-item {
  margin-bottom:34px;
  display: block;
  border:1px solid #DAE9F6;
  color: #1D1D1D;
  padding:25px 25px 25px 80px;
  border-radius: 2px;
  overflow: hidden;
  position: relative;

  &:after {
    position: absolute;
    left:24px;
    top:24px;
    height:33px;
    width:36px;
    background-size:contain;
    background: url('/assets/img/faq.svg');
    content: '';

  }

  &:hover {
    background: #EBF6FC;
    color: #1D1D1D;
  }

  &:last-child {
    margin-bottom:0;
  }

  &.-status- {
    padding:25px 25px 50px 80px;
  }
}

.qualQuestionsList-item--type {
  color: #899EA8;
  margin-bottom:12px;
}
.qualQuestionsList-item--subj {
  margin-bottom:14px;
}

.qualQuestionsList-item--nature {
  color: #9CADB5;
  font-size:14px;
}

.qualQuestionsList-item--status {
  background: #E8F0F7;
  position: absolute;
  left: 0;
  right:0;
  bottom: 0;
  height:28px;
  text-align: center;
  color: #9CADB5;
  line-height:28px;
  font-size:14px;
}

.qualQuestionsList-total {
  margin:32px 0 0;
  text-align: center;
  color: #899EA8;
  font-size:14px;
}
