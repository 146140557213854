.usersList--item {
  @extend .-shadow-;
  margin:0 0 60px;
  background: #FFFFFF;
  //padding:36px 60px;

  .userCard-content {
    padding:36px 60px;

  }

  .spoilerTriggerLink {
    display: block;
    background-color: #E3EDFB;
    text-align: center;
    padding:18px 20px;
    color: #99AFCF;
  }

  .examApplication-card--mainInfo {
    border-bottom: none;
  }
}

.userCard--header {
  border-bottom: 1px solid #DAE9F6;
  padding:0 0 20px;
}

.userCard--header-main {
  float: left;
}

.userCard--status {
  color: #899EA8;
  font-size:16px;
  margin:0 0 4px;
}

.userCard--name {
  font-size:20px;
}

.userCard--header-edit {
  float: right;
}

