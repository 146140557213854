@font-face {
  font-family: 'Font-Name';
  src: url('../fonts/font-name.eot');
  src: url('../fonts/font-name.eot?#iefix') format('embedded-opentype'),
       url('../fonts/font-name.woff') format('woff'),
       url('../fonts/font-name.ttf') format('truetype'),
       url('../fonts/font-name.svg#font-name') format('svg');
  font-weight: normal;
  font-style: normal;
}

a {
  color: #1F80C5;
  text-decoration: none;

  &:hover {
    color: #48A9ED;
  }
}

strong {
  font-weight: bold;
}

h3 {
  color: #1C7FC4;
}

h2.sidebar-title {
  font-weight:normal;
}

.panel-blue {
  background-color: #EBF6FC;
  color: #2A5065;
  padding:30px 36px;
  margin:20px 0;

  ul {
    margin:0;
    padding:0 20px;
  }
}

dl {}

dt  {
  display: block;
  font-weight: bold;
  margin:0 0 4px;
}

dd {
  display: inline-block;
  margin:0 0 20px;
}

.row {}

.col-6 {
  display: block;
  float: left;
  width:50%;
}

.contact-list {
  padding:0 47px;
  font-size:18px;

}


.-text-center- {
  text-align: center;
}

.spoilerTriggerLink {
  position: relative;

  .short {
    display: none;
  }

  .arrow {
    display: block;
    height:8px;
    width: 8px;
    border-top:1px solid #9FB4D2;
    border-right:1px solid #9FB4D2;
    position: absolute;
    top:22px;

    transform: rotate(135deg);
  }

  &.unfolded {
    .arrow {
      top:26px;
      transform: rotate(315deg);
    }
  }
}

.scroll-content {
  height:260px;
  overflow-y: scroll;
}


.miniCard {

  &.-bordered- {
    border-top: 1px solid #dae9f6;
    border-bottom: 1px solid #dae9f6;
    padding:25px 0;
  }

  .miniCard-icon {
    float: left;
    margin: 0 20px 0 0;
  }

  .miniCard-title {
    color: #899EA8;
    margin:0 0 8px;
  }


}


.inline-dl dt{
  display:inline-block
}

.inline-dl dd{
  margin:0;
}

.inline-dl{
  margin: 0 0 10px
}

.panel-blue-qua {
  background-color: #EBF6FC;
  color: #2A5065;
  padding: 10px 36px;
  margin: 20px 0; }
.panel-blue ol {
  margin: 0;}

ol {
  padding-left: 18px; }

ol li {
  margin: 0 0 20px; }

.pdf-qua {
  margin-top: -20px; }

.partners-list a {
  margin: 0px 10px 20px 10px;
}

.-text-blue- {
  color: #1C7FC4;
}
