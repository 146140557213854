.header {
  height:100px;
}

.header--logo {
  margin:18px 0 0;
  display: block;
  float: left;
  height:60px;
  width:347px;
  background: url('/assets/img/logo.svg');
  background-size:contain;
}

.header--actions-list {
  float: right;
  margin:30px 0 0;
}

.header-action {
  display: inline-block;
  margin: 0 10px;
}

.header, .main-nav--container {
  background:linear-gradient(90deg,#c8e1fc,#faf6ef 61.6%,#c5ddf1)
}

.inner-page .header, .inner-page .main-nav--container {
  background:linear-gradient(90deg,#dae7f5,#e5ebf3 61.6%,#e0eaf3)
}

.inverse .header, .inverse .main-nav--container {
  background:linear-gradient(90deg,#dae7f5,#e5ebf3 61.6%,#e0eaf3)
}

.inverse .exam-header {
  background: #ffffff !important;
}


.experts-title {
  font-weight: bold;
  margin: 13px 0 13px;
  font-size: 18px; }
.icon.experts {
  height: 47px;
  width: 47px;
  background-size: contain;
  background: url("/assets/img/icon-6.svg"); }
