* {
  box-sizing: border-box;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}
html {
  font-size: 62.5%;
  background: #ffffff;
}

@import url('https://fonts.googleapis.com/css?family=PT+Sans:400,700&subset=cyrillic');

body {
  font-family: 'PT Sans', sans-serif;
  font-size:16px;
  color: #000000;
  transition: all .4s .3s ease-in;

  &.inverse {
    background: linear-gradient(90deg, #C8E1FC 0%, #E5EAFA 61.6%, #B1D9FD 100%);
  }
}
body,html{
	min-height: 100vh;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
	//overflow: hidden;
}

.content {
  max-width:1300px;
  padding:0 16px;
  margin:0 auto;
  position: relative;
  @extend .clear;
}

#wrapper {
  height: auto;
  min-height: 100%;
  padding: 0 0 111px;


  position: relative;
  overflow-x: hidden;
  margin: 0 auto;
  min-height: 100vh;
  min-width: 1200px;
  max-width: 1920px;
}
