.examApplications-list {
  margin:40px 0;

}

.examApplications-list--item {
  @extend .-shadow-;

  display: block;
  position: relative;
}

.examApplication-card {
  background: #FFFFFF;
}

.examApplication-card--content {
  padding:40px 60px;
}

.examApplication-card--header {
  border-bottom: 1px solid #DAE9F6;

  h3 {
    color: #899EA8;
    font-size:16px;
    font-weight:normal;
  }
  h2 {
    font-size:18px;
    font-weight:normal;
  }

  .examApplications-list--item-options {
    float: right;
  }
}

.dateTimePlace {
  @extend .schedule-item;

  width:100%;
  display: flex;
  justify-content: space-between;
  padding: 28px 32px;

  border-bottom: 1px solid #DAE9F6;

  &.-bordered- {
    border-top: 1px solid #DAE9F6;
    border-bottom: 1px solid #DAE9F6;
  }

}

.examApplication-card--dateTimePlace {
  @extend .schedule-item;

  width:100%;
  display: flex;
  justify-content: space-between;
  padding: 28px 32px;

  border-bottom: 1px solid #DAE9F6;

  &.-bordered- {
    border-top: 1px solid #DAE9F6;
    border-bottom: 1px solid #DAE9F6;
  }

}

.examApplication-card--date {
  border: none;
  height: 36px;
  color: #1f80c5;
  line-height: normal;

  span {
    font-size: 36px;
    font-weight: 700;
    color: #1f80c5;
    display: inline-block;
    margin: 0 10px 0 0;
  }
}

.examApplication-card--time {
  font-size: 24px;
  height: 40px;
  line-height: 40px;


}

.examApplication-card--examStart {
  background-color: #F3F6FA;
  padding:26px 0;
  border-bottom: 1px solid #DAE9F6;

}

.infoBlock {
  margin:0 0 20px;

  .infoBlock--title {
    color:#899EA8;
    font-size:16px;
    margin:0 0 10px;
  }

  strong {
    font-size:18px;
  }
}

.examApplication-card--mainInfo {
  padding:30px 0 0;
  border-bottom: 1px solid #DAE9F6;

  .examApplications-list--item & {
    border-bottom: none;
  }

  .examApplication-card--mainInfo-item {
    margin:0 0 20px;

    .mainInfo-item--title {
      color:#899EA8;
      font-size:16px;
      margin:0 0 10px;
    }
  }
}

.examApplication-card--docUpload {
  padding:26px 0 0;
}
.docUpload--title {
  color: #899EA8;
  margin:0 0 18px;
}

.docUpload--info {
  .warning {
    color: #FF9C0E;
  }
}

.docUpload--list {
  margin:20px 0;
}

.docUpload--input {
  position: relative;
  z-index:1;

  //cursor: pointer;
  border:1px solid #DAE9F6;
  padding:26px 20px;
  margin:0 0 20px;

  &:hover {
    background-color: #EBF6FC;
  }

  label {
    cursor: pointer;
  }

  input[type='file'] {
    opacity: 0;
    position: absolute;
    z-index: -1;
  }

  .docUpload--input-form {
    position: relative;
  }

  .docUpload--input--action {
    color: #4F94CC;
    float: right;
    text-align: right;
    position: absolute;
    right:0;
    top:0;


    .-upload- {
      cursor: pointer;
      display: inline-block;
    }



    .-delete- {
      display: none;
      z-index:2;
      position: relative;

      &:hover {
        color: red;
      }
    }
  }

  .-download-template- {
    font-size:14px;
    display: block;
  }

  &.uploaded {
    color: #4F94CC;

    .docUpload--input--action {
      color: #899EA8;

      .-upload- {display: none;}
      .-delete- {display: block; cursor: pointer;
        z-index:99;}
    }

    .-download-template- {
      display: none;
    }


  }
}

.examApplication-card--footer {
  background-color: #F3F6FA;
  padding:40px 60px;
}

.examApplication-card--section-title {
  color: #899EA8;
  margin:0 0 18px;
}

.history-item {
  margin:0 0 25px;
}

.history-item--date {
  font-size:14px;
}
