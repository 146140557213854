.-bordered- {
  border-top: 1px solid #DAE9F6;
  border-bottom: 1px solid #DAE9F6;
  padding: 28px 32px;
}

.-text-red- {color: #F35858;}
.-text-green- {color: #6BBF3D}

.secondary-text {
  color: #899EA8;
  display: inline-block;
}

.examResult-list {}
.examResult-list--item {
  margin: 0 0 57px;

  .-bordered- {
    padding:28px 0;

    .infoBlock {
      margin:0;
    }
  }
}

.examResult-card {
  @extend .-shadow-;
  background: #FFFFFF;
  padding:48px 60px;
}

.examResult-card--title {
  margin: 0 0 20px;

  i {
    float: left;
    width:32px !important;
    height:35px !important;
    margin: 4px 20px 0 0;
  }

  .secondary-text {
    margin: 0 0 4px;
  }

  .examResult-card--qualName {
    font-size:18px;
  }
}

.examResult-card--dateTimePlace {
  margin: 0 0 20px;
}

.examResult-card--user-info {
  margin:0 0 20px;
}

.examResult-card--result-percent {

}

.examResult-card--answer-list {
  h3 {
    font-weight:normal;
    color: #000000;
  }

}

.examResult-card--answer-item {
  border: 1px solid #DAE9F6;
  padding:26px;
  margin: 0 0 26px;
  border-radius: 3px;

  .answer-item--title {
    margin:0 0 20px;

    i {
      margin: 0 18px 0 0;
    }

    .answer-item--status {
      float: right;
      margin: 10px 0 0;
    }
  }

  .answer-item--answer {
    h4 {
      font-weight: normal;
    }
  }
}
