.form-wrapper {
  //background: #ffffff;
  margin:88px 0 44px;
}

.request-form {
  padding:54px 100px;
  background: #ffffff;
  max-width: 770px;
  margin:0 auto;
}

.auth-form {
  max-width: 570px;
  margin:0 auto;
  //padding:54px 70px 27px;
  padding:38px 70px 27px;
  background: #ffffff;
}

.form-title {
  text-align: center;
  font-size:34px;
  //margin: 0 0 48px;
  margin:0 0 32px;
}

.input-item {
  margin:0 0 35px;

  &.-disabled- {
    opacity: 0.5;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top:0;
      right: 0;
      left: 0;
      bottom: 0;
      display: block;
    }
  }

  &:last-child {
    margin-bottom:0;
  }

  &.-two- {
    .input-item--column {
      width:49%;
      display: inline-block;

      &:last-child {
        float: right;
      }
    }
  }

  input[type='submit'] {
    border: none;

    &:hover {
      cursor: pointer;
    }

    &.btn {
      padding-left:26px;
      padding-right:26px;
    }
  }

  span {
    color: #B4BFCB;
    font-size:12px;
  }

  label.error {
    color: #FF9C0E;
    margin:4px 0 0 20px;
    display: block;
    //position: absolute;
    font-size:14px;
    float: right;

    #confirm-form & {
      float: none;
      margin:4px 0;
    }
  }

  input[type='text'], input[type='password'], input[type='email'] {
    display: block;
    width:100%;
    background-color: #EBF6FC;
    border:none;
    padding:12px 14px;
    font-size:16px;

    /* Webkit */
    &::-webkit-input-placeholder { color: #4D5660; opacity: 1}

    /* Firefox 4-18 */
    &:-moz-placeholder { color: #4D5660;opacity: 1 }

    /* Firefox 19+ */
    &::-moz-placeholder { color: #4D5660; opacity: 1}

    /* IE10+ */
    &:-ms-input-placeholder { color: #4D5660; opacity: 1}

    &.error {
      background: #F9F5EF;
      border: 1px solid #FF9C0E;
    }
  }


  select {
    display: block;
    width:100%;
    background-color: #EBF6FC;
    padding:12px 14px;
    font-size:16px;
    border:none;

    color: #4D5660;

    option {
      color: #4D5660;
    }
  }
}

.form-desc {
  margin:30px 0;
}

.input-ecp {
  text-align: center;
  color: #B3C4D1;
  margin:0 0 10px;

  .input-ecp--status {
    color: #F89C00;
  }
}

.confirm-form--repeat-send-btn {
  color: #000000;

  span {
    font-size:inherit;
    color: #1F80C5;
  }

  &:hover {
    color: #000000;

    span {
      color: #FF9C0E;
    }
  }
}

.checkbox {
  .checkbox--label {
    display: block;
  }
  .checkbox--error-container {
    float: left;
  }
}

.radio-label {
  &:hover {
    cursor:pointer;
  }
}

.iradio_flat-blue {
  margin:0 6px 0 0;
}
