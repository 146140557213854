.-single-form-page- {

}

.single-form {
  @extend .-shadow-;

  background: #FFFFFF;
  max-width: 770px;
  margin:0 auto;
  padding:48px 100px;

  form {
    fieldset {
      border: none;
      padding:0 0 40px;
      margin:0 0 28px;
      border-bottom: 1px solid #DAE9F6;

      &:last-child {
        border:none;
      }
    }
  }
}

.single-form--title {
  @extend .section-title;

  text-align: center;
}

.single-form--input-item {
  margin:0 0 22px;

  &.-double-margin- {
    margin:0 0 44px;
  }

  .input-item--label {
    display: block;
    color: #899EA8;
    margin:0 0 14px;
  }

  .select {
    display: block;
    width:100%;
  }

  input[type='text'],input[type='password'],select,textarea {
    background: #EBF6FC;
    padding:14px 18px;
    border: 1px solid #D2E0E7;
    display: block;
    width:100%;
    border-radius: 3px;
  }

  textarea {
    resize: none;
    height:100px;
  }
}

.single-form--help-text {
  display: block;
  color: #899EA8;
  margin:0 0 24px;

  a {
    color: #899ea8;
    text-decoration: underline;
  }
}

.single-form--input-group {
  .single-form--input-item {
    width:49%;
    float: left;

    &:last-child {
      float: right;
    }
  }
}

.single-form--btn-group {
  text-align: center;

  .btn {
    margin:0 15px;
  }
}

.single-form--remove-option {
  position: absolute;
  display: none;
  left: -25px;
  top:0;
  width:25px;
  height:100%;
  vertical-align: middle;

  span {
    position: absolute;
    top:46%;
    left:5px;
  }
}

.single-form--conformity {
  margin:0 0 40px ;
  padding:16px 0 16px 20px;
  border-left: 1px solid #D2E0E7;
  position: relative;

  &:hover {
    .single-form--remove-option {
      display: block;
    }
  }
}

.single-form--text-list {

  .single-form--input-item {
    position: relative;

    .single-form--remove-option {
      span {
        top:58%;
      }
    }

    &:hover {
      .single-form--remove-option {
        display: block;
      }
    }
  }
}

.single-form--checkbox-list {

  .single-form--input-item {
    position: relative;

    .single-form--remove-option {
      span {
        top:58%;
      }
    }

    &:hover {
      .single-form--remove-option {
        display: block;
      }
    }
  }

}

.-checkbox-option- {

  .single-form--input-item {
    width:460px;
    float: left;

  }

  .checkbox--label {
    float: right;
    margin:48px 10px 0 0;
  }



}
