.main {
  //z-index:-2;

  position: relative;
}

.-shadow- {
  box-shadow: 0 0 25px 0 rgba(19,74,121,0.13);
}

.block-shadow {
  box-shadow: 0 0 25px 0 rgba(19,74,121,0.13);
  border: 1px solid transparent;


  &:hover {
    border-color: #1F80C5;
    box-shadow: 0 22px 35px 0 rgba(19,74,121,0.21);
  }

}

.promo-block {
  height: 448px;
  overflow: hidden;
  background-color: #207FC9;
  background: linear-gradient(90deg, #C8E1FC 0%, #FAF6EF 61.6%, #C5DDF1 100%);

  .promo-block--content {
    max-width: 530px;
    margin:105px 0 0 ;
    float: left;
  }

  .promo-block--title {
    font-size:34px;
    font-weight:bold;
    margin:0 0 34px;
    line-height:1.4;
    color: #0E0E0E;
  }

  .promo-block--text {
    color: #646a6f;
    margin:0 0 41px;
    font-size:16px;
  }

  .promo-block--btn {
    font-size:18px;
  }

  .promo-block--map {
    float: right;
    //width:100%;

    #map {
      margin:49px 0 0;
      display: block;
      height:351px;
      width: 672px;
      //background-color: #fff;
      background-size: 672px 351px;
      background-image: url('/assets/img/map.svg');

    }
  }

  .promo-block--img {
    float: right;
    height:448px;
    line-height:448px;
    width:50%;
    text-align: center;

    img {
      display: inline-block !important;
      vertical-align: middle;
      width:auto !important;
    }

    &.secondSlideImg {
      text-align: left;

      img {
        vertical-align: bottom;
      }
    }
  }

  .owl-dots {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
  }
}


section.section {
  padding:57px 0;

  .section-title  {
    font-size:33px;
    margin:0 0 34px;
    color: #000000;
    font-weight:500;
  }

  &.-section-blue-bg- {
    background: linear-gradient(90deg, #F0EDFC 0%, #E5EAFA 61.6%, #C4DFF6 100%);
  }
}

.step-list {
  //height:136px;
  margin:70px 0 0;
}

.step-item {
  width:24%;
  display: inline-block;
  text-align: center;
  vertical-align: top;
  padding:0 5px;
  margin:0 0 40px;
  position: relative;

  &:last-child {
    &:after {display: none;}
  }

  &:after {
    position: absolute;
    right: 0;
    top:15px;
    content: '';
    display: block;
    width:15px;
    height:26px;
    background: url('/assets/img/arrow-right.svg');
    -webkit-background-size:contain;
    background-size:contain;
  }

  .step-item--img {
    background-size: contain;
    display: inline-block;
    text-align: center;
    margin:0 0 26px;
    min-height: 57px;

    &.reg {
      height:58px;
      width:55px;
      background-size:contain;
      background: url('/assets/img/reg.svg');
    }

    &.sign-up {
      height:58px;
      width:51px;
      background-size:contain;
      background: url('/assets/img/zap.svg');
    }

    &.pay {
      height:58px;
      width:58px;
      background-size:contain;
      background: url('/assets/img/pay.svg');
    }

    &.come {
      height:58px;
      width:58px;
      background-size:contain;
      background: url('/assets/img/comeon.svg');
    }

    &.get-verified {
      height:58px;
      width:56px;
      background-size:contain;
      background: url('/assets/img/results.svg');
    }

    &.getinfo {
      height:58px;
      width:52px;
      background-size:contain;
      background: url('/assets/img/getinfo.svg');
    }

    &.demo {
      height:52px;
      width:58px;
      background-size:contain;
      background: url('/assets/img/demo.svg');
    }

    &.exam {
      height:57px;
      width:48px;
      background-size:contain;
      background: url('/assets/img/exam.svg') no-repeat;
    }

    &.no-time {
      height:48px;
      width:47px;
      background-size:contain;
      background: url('/assets/img/no-time.svg');
    }

    &.pdf {
      height:16px;
      width:16px;
      background-size:contain;
      background: url('/assets/img/download.svg');
    }

    &.question {
      height:45px;
      width:48px;
      background-size:contain;
      background: url('/assets/img/faq.svg');
    }

    &.user {
      height:42px;
      width:42px;
      background-size:contain;
      background: url('/assets/img/user.svg');
    }

    &.user-mini {
      height:15px;
      width:13px;
      background-size:contain;
      background: url('/assets/img/user-mini.svg');
    }


  }

  .step-item--text {
    display: block;
    line-height:1.4;
    font-size:14px;
  }
}

.events-schedule {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: flex-start;
  align-items: flex-start;
}

.schedule-list {
  &.-main-page-schedule- {
    .schedule-item {
      @extend .block-shadow;
    }
  }
}

.schedule-item {
  background: #ffffff;
  width:270px;
  padding:28px 32px;
  display: block;
  color: #000000;

  //@extend .block-shadow;

  &:hover {
    color: #000000;

    .schedule-item--date {
      border-color: #0275CF;
    }
  }

  .schedule-item--date {
    line-height:1;
    color: #B4CBE6;
    padding:0 0 16px;
    margin:0 0 16px;
    border-bottom: 1px solid #DAE9F6;

    span {
      font-size:36px;
      font-weight:bold;
      color: #1F80C5;
      display: inline-block;
      margin:0 10px 0 0;
    }
  }

  .schedule-item--events-list {}

  .schedule-item--events-item {
    padding:4px 0;

    span {
      display: block;
      color: #0275CF;
    }
  }

  .events-placeholder {
    text-align: center;

    i {
      margin:0 0 16px;
    }

    span {
      display: inline-block;
      font-size:18px;
      line-height:1.3;
    }
  }

}

.events-link {
  text-align: center;
  margin:34px 0 0;

  .btn {
    border:1px solid #1F80C5;
  }
}

section.partners-section {
  padding:34px 0;
}

.patners-list {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}

.partners-item {
  //width:33%;
  //display: inline-block;
  //text-align: center;
}

section.inner-page {
  float: right;
  min-height: 500px;
  width:100%;
  max-width: calc(100% - 271px);
  padding: 57px 0 57px 84px;

  &.exam-page {
    max-width: calc(100% - 300px);
  }
}


