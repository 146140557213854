.payChoiceForm--errorContainer {
  text-align: center;
  color: #FF9C0E;
  margin:0 0 27px;
}

.payChoiceForm-total {
  text-align: right;
  margin:20px 0 0;
}

.payChoiceForm {
  padding:20px 0;

  fieldset {
    border-bottom: none !important;
    padding: 0 0 20px !important;
    margin:0 0 10px !important;
  }
}

.payInput {
  .checkbox--label {
    border: 1px solid #DAE9F6;
    padding:25px 20px;
    border-radius: 4px;
    display: block;

    &:hover {
      background-color: #EBF6FC;
    }
  }
}

.payInput--systemLogo {
  height:26px;
  width:96px;
  float: right;

  .-visa-master- & {
    background: url("/Assets/img/visaMaster.png");
  }

  .-yandex-money- & {
    background: url("/Assets/img/yandexMoney.png");
  }
}

.single-form--footer-text {
  text-align: center;
}


.paySuccessPage {

  .paySuccess-text {
    margin:0 0 40px;
    font-size:18px;
  }

  .examResult-card--dateTimePlace {
    margin:0 0 40px;
  }
}
