.main-nav--container {
  position: relative;
  z-index:2;

  background:linear-gradient(90deg,#c8e1fc,#faf6ef 61.6%,#c5ddf1);

  .content {
    position: inherit;
  }

}

.angle-container {
  position: relative;

  &:before,&:after {
    position: absolute;
    bottom:-20px;
    content: '';
    display: block;
    width:0;
    height:0;
  }

  &:before {
    left:16px;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #024173;
    border-top: 10px solid #024173;
    border-left: 10px solid transparent;
  }

  &:after {
    right:16px;
    border-bottom: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #024173;
    border-left: 10px solid #024173;
  }
}

.main-nav {
  height: 50px;
  padding:0 20px;
  background-color: #1C7FC4;
  box-shadow: 0 11px 32px 0 rgba(9,68,117,0.36);

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-content: center;
  align-items: center;

  //z-index:1;
  position: relative;

  background: -moz-linear-gradient(top, #1996ed 0%, #0d79c5 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #1996ed 0%,#0d79c5 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #1996ed 0%,#0d79c5 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1996ed', endColorstr='#0d79c5',GradientType=0 ); /* IE6-9 */


  &:before,&:after {
    background: #0871bb; /* Old browsers */
    background: -moz-linear-gradient(top, #0871bb 0%, #0662a1 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #0871bb 0%,#0662a1 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #0871bb 0%,#0662a1 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0871bb', endColorstr='#0662a1',GradientType=0 ); /* IE6-9 */
  }

  &:before {
    z-index:-1;
    content: '';
    background-color: #0661A0;
    position: absolute;
    height:50px;
    top: 20px;
    left: -200%;
    right: calc(100% - 20px);
  }

  &:after {
    z-index:-1;
    content: '';
    background-color: #0661A0;
    position: absolute;
    height:50px;
    top: 20px;
    right: -200%;
    left: calc(100% - 20px);
  }




  a {
    color: #ffffff;
    vertical-align: middle;
    min-height: 100%;
    line-height:50px;
    padding:0 6px;
    font-size:16px;

    &:hover {
      color: #ffffff;
      background: #0661A0;
    }
  }


}

.inner-nav {
  background: #ecf6fc;
  margin:0 0 40px;
  border-radius: 3px;
  overflow: hidden;

  a {
    color: #4e5660;
    padding:16px 24px;
    display: inline-block;

    &.active {
      background: #3480c6;
      color: #ffffff;
    }
  }
}

@media (max-width: 1300px) {
  .main-nav a {
    padding:0 4px;
  }
}
