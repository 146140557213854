.single-page {
  background: #ffffff;
  padding:54px 48px;
}

.examCard {}

.examCard--content {
  float: left;
  width:40%;
}

.examCard--title {
  margin: 0 0 22px;
  font-size:32px;
  font-weight:normal;
  line-height:1;
}

.examCard--desc {
  margin: 0 0 30px;
}

.examCard--block-title {
  line-height:1;
  font-size:22px;
  margin:0 0 18px;
}

.examCard--place {
  margin: 0 0 30px;

  .examCard--place-time {
    p {
      margin: 0 0 4px;

      span {
        font-weight:bold;
      }
    }
  }
}

.examCard--location {
  margin:0 0 30px;
}

.examCard--organizer {
  margin:0 0 36px;
}

.examCard--mapAndPlace {
  float: right;
  width:60%;
}

.examCard--map {
  margin: 0 0 20px;
  height:400px;
}

.place-item {
  border: 1px solid #dae9f6;
  margin:0 16px 20px ;
  padding:20px;
  border-radius: 3px;
  @extend .clear;
}

.place-item--img {
  float: left;
  margin: 0 30px 0 0;
  width: 85px;
  height:80px;
  overflow: hidden;
  position: relative;


  img {
    max-height: 100%;
    position: absolute;
    top:0;
    right: 0;
    left:50%;
    bottom: 0;
    transform: translateX(-50%);
  }
}

.place-item--desc {
  margin:0 0 0 115px;
}

.place-item--main-info {
  margin: 0 0 20px;

  .place-item--title {
    margin: 0 0 5px;
    font-size:20px;
  }

  span {
    color: #899ea8;
    margin:0 10px 0 0;
    font-size:14px;
  }


}

.place-item--contacts {
  .miniCard {
    .miniCard-title {
      margin: 0 0 4px;
    }
  }
}

