.userAdmission-item {
  border: 1px solid #DAE9F6;
  padding:22px 22px 22px 84px;
  margin:0 0 34px;
  border-radius: 3px;

  &:last-child {
    margin:0;
  }

  &:hover {
    background-color: #EBF6FC;
  }
}

.userAdmission--header {
  margin:0 0 20px;
  position: relative;
}

.userAdmission--header-icon {
  position: absolute;
  left:-61px;
}

.userAdmission--name {
  font-size:18px;
  margin:0 0 4px;
}

.userAdmission--viewInfo {
  font-size:14px;
}

.userAcceptForm {
  h3 {
    color: #000000;
    font-weight: normal;
    font-size:22px;
    margin:38px 0;
  }
}

.single-form form.userAcceptForm .userAdmission  {
  border-bottom: none;
  padding: 0;
  margin: 0 0 44px;
}

.userAdmission--inputlist {
 .single-form--input-group {
   .single-form--input-item {
     margin:0;

     input[type='checkbox'] {
       margin: 0 6px 0 0;
     }
   }
 }
}


.userBreakExam {
  margin:0 0 20px;
}
