.examGroup-list--item {
  @extend .-shadow-;

  margin:0 0 60px;

  .examApplication-card--dateTimePlace {
    padding-top: 0;
  }

  .examApplication-card--mainInfo {
    border-bottom: none;
  }

  .spoilerTriggerLink {
    display: block;
    background-color: #E3EDFB;
    text-align: center;
    padding:18px 20px;
    color: #99AFCF;
  }
}


.examGroupParticipantsInfo {
  border: 1px solid #DAE9F6;
  padding:24px 20px;
  margin: 0 0 30px;
  border-radius: 4px;

  .examGroupParticipantsInfo--quantity {
    float: left;
  }

  .examGroupParticipantsInfo--goToRequest {
    float: right;
  }
}

.examGroupActions {
  text-align: center;

  .btn {
    margin:0 10px;
  }
}




.pagination {
  text-align: center;
  margin:0 0 30px;

  a {
    display: inline-block;
    margin:0 4px;
    color: #899EA8;
    padding:4px 10px;
    border-radius: 4px;

    &.active {
      background: #1C7FC4;
      color: #FFFFFF;
    }
  }
}
