.question-list {

}

.question-item {
  padding:35px 0;
  border-top: 1px solid #D5E4EF;
  border-bottom: 1px solid #D5E4EF;
}

.question-item--title {

  color: #1C7FC4;
  font-size:20px;
  font-weight:bold;
  position: relative;

  i {
    position: absolute;
    left:0;
    top:-9px;
  }

  span {
    margin:0 0 0 72px;
  }
}

.question-item--answer {
  padding:20px 0 0 72px;
  font-size:18px;
}


.schedule-list {
  h3 {

  }

  &.schedule-page {

    .schedule-item {
      width:100%;
      display: flex;
      justify-content: space-between;

      border-top: 1px solid #D5E4EF;
      color: #000000;

      .schedule-item--date {
        width: 140px;
        padding:0;
        margin:0;
        border: none;
        height:36px;
        line-height:normal;
      }

      .schedule-item--time {
        font-size:24px;
        height:40px;
        line-height:40px;
      }

      .schedule-item--place {
        width:240px;
      }

      .schedule-item--place-city {
        font-size:18px;
        font-weight:bold;
        margin:0 0 4px;
      }

      &:last-child {
        border-bottom: 1px solid #D5E4EF;
      }

      &:hover {
        background: #EBF6FC;
      }
    }
  }
}

.schedule-filter {}

.schedule-filter--input {
  margin:0 0 20px;
  border-radius: 3px;
  overflow: hidden;
  background: #EBF6FC;


  select {
    width:100%;
    border: none;
    background: #EBF6FC;
    padding: 18px;
  }
}

.partners-list {
  &.-partner-page- {

    .partners-item {
      background: #ffffff;
      display: inline-block;
      width:270px;
      padding:10px;

      img {
        max-width: 100%;
      }
    }
  }
}
